import { TextField, styled } from '@mui/material';
import { Column, Row } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/close-grey.svg';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useUpdateTimesheetCell } from '../../../../queries/hooks/useUpdateTimesheetCell';
import { LoadingId, TimesheetRow } from '../../../../types';
import { useSingleTimesheet } from '../../../../queries/hooks/useSingleTimesheet';
import { useParams } from 'react-router';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../../state/UIState';

type Props = {
  getValue: () => unknown;
  row: Row<TimesheetRow>;
  column: Column<TimesheetRow>;
  table: any;
  headerDate?: string;
  weekDates?: string[];
};

export const EditableNumberCell = ({
  getValue,
  row,
  column,
  table,
  headerDate,
  weekDates,
}: Props) => {
  const initialValue = getValue() ?? '';
  const { id } = useParams();
  const isOnboarding = id === 'demo';
  const { pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const [showClear, setShowClear] = useState(false);

  const { mutate: onUpdateCell } = useUpdateTimesheetCell();

  const { data: singleTimesheet } = useSingleTimesheet({
    id: Number(id),
    enabled: !isOnboarding,
  });

  const monthLabel = useMemo(() => {
    if (headerDate) {
      return Number(headerDate.split(',')[1].split('/')[0]);
    }
    return 0;
  }, [headerDate]);

  const currentTSMonth = useMemo(() => {
    if (singleTimesheet?.weekDates.length) {
      return Number(singleTimesheet?.weekDates[0].split('-')[1]);
    }
    if (singleTimesheet?.holidays.length) {
      return singleTimesheet?.holidays[0].month;
    }
  }, [singleTimesheet]);

  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const emptyLabel = useMemo(() => {
    if (singleTimesheet) {
      if (headerDate) {
        if (monthLabel !== currentTSMonth) return '';
      }
      if (singleTimesheet?.weekDates.length !== 5 && singleTimesheet?.holidays.length !== 0) {
        return 'N/A';
      }
      return '';
    }
    return '';
  }, [singleTimesheet, currentTSMonth, headerDate, monthLabel]);

  const editDisable =
    row.original.editDisabled ||
    !weekDates?.some((date) => {
      const headerDateFormatted = headerDate?.split(',')[1].trim().split('/') ?? [];
      return (
        date ===
        `${headerDateFormatted[2]}-${Number(headerDateFormatted[0])}-${Number(
          headerDateFormatted[1]
        )}`
      );
    });

  const handleUpdate = (newValue) => {
    const prevValue = row.original[column.id];

    const rowId = row.original.id;

    const payload = {
      [column.id]: newValue,
    };

    if (prevValue === newValue) {
      setShowClear(false);
      return;
    }

    table.options.meta?.updateData(row.index, column.id, newValue);
    startLoading(LoadingId.updateCell);
    onUpdateCell(
      {
        id: rowId,
        payload,
      },
      {
        onSuccess: () => {
          setShowClear(false);
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to update cell' });
        },
        onSettled: () => {
          stopLoading(LoadingId.updateCell);
        },
      }
    );
  };
  return (
    <NumberInputField
      autoComplete='off'
      name='numberformat'
      onFocus={(e) => {
        e.target.select();
      }}
      style={{
        width: isSmallScreen ? '100px' : '100%',
      }}
      className='formatted-numberformat-input'
      disabled={editDisable}
      onMouseEnter={() => {
        if (editDisable) return;
        if (value !== 0) {
          setShowClear(true);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Backspace') {
          const newValue = value?.toString().slice(0, value?.toString().length - 1);
          setValue(Number(newValue));
        }
      }}
      onMouseLeave={() => {
        setShowClear(false);
      }}
      InputProps={{
        endAdornment: showClear ? (
          <ClearIcon
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 5,
            }}
            onClick={() => {
              handleUpdate(0);
            }}
          />
        ) : null,
      }}
      size='small'
      type='string'
      value={`${typeof value === 'number' ? `${value}%` : emptyLabel}`}
      onChange={(e) => {
        const value = e.target.value.match(/[0-9]+/g)?.join('') || '0';

        setValue(Number(value));
      }}
      onBlur={(e) => {
        const value = e.target.value.match(/[0-9]+/g)?.join('') || '0';
        const newValue = Number(value);
        handleUpdate(newValue);
      }}
    />
  );
};

const NumberInputField = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.colors.textAccent.disabled,
  },
  '& .MuiInputBase-root': {
    padding: 0,
  },
}));
