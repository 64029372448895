import { IconButton, styled, TextField } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-grey.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { FC, useCallback, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { isSmallScreenState, selectedThemeState } from '../../state/UIState';
import { THEME } from '../../types';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { dateToUTCDateOnly } from '../../utils/formatters';

interface Props {
  selectedDate: string | null;
  setSelectedDate: (newDate: Date | null) => void;
  clearable?: boolean;
  placement?: 'bottom-start' | 'bottom-end' | 'bottom';
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
  minDate?: string | null;
  maxDate?: string | null;
  views?: CalendarPickerView[];
  inputFormat?: string;
  placeholder?: string;
}

const Wrapper = styled('div')<{ isActive: boolean; clearable: boolean, isSmallScreen: boolean }>`
  .custom-date-picker {
    width: 100%;
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.border.accent : theme.colors.surfaceBackground.highlighted};
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.border.accent : theme.colors.border.default};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.border.default};
    }
    .MuiInputBase-root {
      height: 36px;
      width: ${({ clearable, isSmallScreen }) => (isSmallScreen ? '140px' : clearable ? '170px' : 'auto')};
      padding-left: 0;
      padding-right: 0;
    }
    .MuiMonthPicker-root {
      width: auto;
      margin: 0;
    }
    .MuiInputBase-input {
      font-family: ${({ theme }) => theme.text.font.basierRegular};
      line-height: 18px;
      margin-left: -10px;
      color: ${({ theme }) => theme.colors.text.main};
      caret-color: transparent;
    }
    .MuiButtonBase-root {
      padding: 6px;
      margin: 6px;
    }
    svg {
      margin-top: -2px;
    }
  }
`;

export const DatePicker: FC<Props> = ({
  selectedDate,
  setSelectedDate,
  clearable = false,
  placement = 'bottom',
  disablePast = false,
  disableFuture = false,
  disabled = false,
  minDate,
  maxDate = new Date().toISOString(),
  views = ['year', 'month', 'day'],
  inputFormat = window.navigator.language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy',
  placeholder = window.navigator.language === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
}) => {
  const [isActive] = useState(false);
  const [selectedTheme] = useAtom(selectedThemeState);
  const isSmallScreen = useAtomValue(isSmallScreenState);

  // Helper: Parse date string as a "local date" (ignores timezones)
  const parseDate = (dateStr: string | null) => {
    if (!dateStr) return null;
    const isoDate = new Date(dateStr).toISOString();
    const [year, month, day] = isoDate.split('T')[0].split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const selectedValue = useCallback(
    (date: string | null) => {
      if (!date) return null;
      if (views.includes('day')) {
        return parseDate(selectedDate);
      }
      if (!views.includes('day') && !views.includes('month') && views.includes('year')) {
        return new Date(Number(date), 0, 10);
      }
      return selectedDate;
    },
    [selectedDate, views]
  );

  return (
    <Wrapper clearable={clearable} isActive={isActive} isSmallScreen={isSmallScreen}>
      <MuiDatePicker
        value={selectedValue(selectedDate)}
        views={views} // Dynamically pass views
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={dateToUTCDateOnly(minDate ?? '') || undefined}
        maxDate={dateToUTCDateOnly(maxDate ?? '') || undefined}
        disabled={disabled}
        onChange={(newValue) => {
          if (newValue === null) {
            setSelectedDate(null as unknown as Date);
            return;
          }
          if (newValue.toString() === 'Invalid Date') {
            return;
          }
          setSelectedDate(newValue as unknown as Date);
        }}
        renderInput={(params) => (
          <TextField
            autoComplete='off'
            {...params}
            inputProps={{ ...params.inputProps, placeholder: placeholder }}
            InputProps={{
              ...params.InputProps,
              endAdornment:
                clearable && selectedDate ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedDate(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  params?.InputProps?.endAdornment
                ),
            }}
          />
        )}
        InputProps={{ placeholder: 'MM/DD/YYYY' }}
        PopperProps={{
          className: `custom-datepicker-popup custom-datepicker-popup-${
            selectedTheme === THEME.DARK ? selectedTheme : THEME.LIGHT
          }`,
          placement: placement,
          disablePortal: true,
          style: { transform: 'translateY(20px)' },
        }}
        openTo={views.includes('day') ? 'day' : views[0]} 
        components={{
          OpenPickerIcon: isActive ? CalendarIcon : CalendarIcon,
        }}
        inputFormat={inputFormat}
      />
    </Wrapper>
  );
};
