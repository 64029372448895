import { useState } from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import { AccountantSingleTimesheetWeekdayTable } from './AccountantSingleTimesheetWeekdayTable';
import { AccountantSingleTimesheetWeekTable } from './AccountantSingleTimesheetWeekTable';
import { useParams } from 'react-router-dom';
import { DownloadCSVLink } from './DownloadCSVButton';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { AccountantTSView as TimesheetTab, TimesheetStatus } from '../../../types';

export function AccountantSingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TimesheetTab.weekday);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });
  const isApprovedTS = singleTimesheet?.status === TimesheetStatus.CLOSED;

  const handleTabChange = (_: React.SyntheticEvent, newValue: TimesheetTab) => {
    setSelectedTab(newValue);
  };

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            label='By Weekday'
            value={TimesheetTab.weekday}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
          <Tab
            label='By Week'
            value={TimesheetTab.week}
            sx={{
              color: colors.text.main,
              textTransform: 'none',
            }}
          />
        </Tabs>
        {isApprovedTS && <DownloadCSVLink selectedTab={selectedTab} timesheet={singleTimesheet} />}
      </Box>
        {selectedTab === TimesheetTab.weekday && (
          <AccountantSingleTimesheetWeekdayTable
            weeklyTimesheet={singleTimesheet.weeklyTimesheets}
            timesheetRows={singleTimesheet.timesheetRows}
          />
        )}
        {selectedTab === TimesheetTab.week && (
          <AccountantSingleTimesheetWeekTable weeklyTimesheet={singleTimesheet.weeklyTimesheets} />
        )}
    </>
  );
}
