import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useUsersTableColumns } from '../hooks/useUsersTableColumns';
import { useCallback, useMemo, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { TableV1 } from '../../../components/Table/TableV1';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { SelectItem } from '../../../types';
import { Typography } from '../../../components/Typography/Typography';
import { useUsers } from '../../../queries/hooks/useUsers';

const Wrapper = styled('div')``;

const FiltersSection = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;

export const UsersTable = () => {
  const { isLoading: areUsersLoading, data: users } = useUsers();
  const { colors } = useTheme();
  const columns = useUsersTableColumns();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedTradeStatus, setSelectedTradeStatus] = useState<SelectItem | null>(null);

  const table = useReactTable({
    data: users ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (user) => String(user.id),
  });

  const sortedUniqueUserRoles = useMemo(
    () =>
      Array.from(table.getColumn('role')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value) => ({ id: value, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, users]
  );

  const onChangeUserRoleFilter = useCallback(
    (userRole: SelectItem | null) => {
      setSelectedTradeStatus(userRole);
      table.getColumn('role')?.setFilterValue(userRole?.id ?? '');
    },
    [table]
  );

  const isResetDisabled = useMemo(() => {
    if (columnFilters.length === 0) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    table.getColumn('name')?.setFilterValue('');
    table.getColumn('role')?.setFilterValue('');
    setSelectedTradeStatus(null);
  }, [table]);

  if (areUsersLoading || !users) return <TableSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersSection>
        <SearchInput
          placeholder='Search'
          style={{ width: '370px', height: '36px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <SingleSelect
          style={{ width: '210px' }}
          listboxStyle={{ height: '195px' }}
          options={sortedUniqueUserRoles}
          value={selectedTradeStatus}
          onChange={(_, type) => onChangeUserRoleFilter(type)}
          disablePortal
          fieldPlaceholder='User Type'
        />
        <Button
          onClick={onFiltersReset}
          variant='text'
          style={{ height: '28px', marginTop: '2px' }}
          disabled={isResetDisabled}
          sx={{
            '.MuiButton-startIcon': { marginRight: '2px' },
            '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          }}
        >
          <Typography variant='subtitle1' color={colors.textAccent.default}>
            Reset Filters
          </Typography>
        </Button>
      </FiltersSection>
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </Wrapper>
  );
};
