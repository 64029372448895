import { Button, Icon, IconButton, TextField, styled, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close-grey.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-icon-default.svg';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { IAddModalFields } from './HolidayFormModal';
import { FormField } from '../../../components/FormField/FormField';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Switch } from '../../../components/SwitchButton/Switch';
import { Typography } from '../../../components/Typography/Typography';
import { addDaysToDate, formatDateToString } from '../../../utils/formatters';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { HolidayTypeItems, HolidayFrequencyItems } from '../../../types';

export function HolidayFormContent() {
  const { colors } = useTheme();
  const { handleChange, handleBlur, values, setFieldValue, setFieldTouched } =
    useFormikContext<IAddModalFields>();

  useEffect(() => {
    if (!values.isMultiday) {
      setFieldValue('endDate', null);
    }
  }, [values.isMultiday, setFieldValue]);

  const minDate = useMemo(() => {
    if (values.startDate) return addDaysToDate(values.startDate, 1).toISOString();
    return null;
  }, [values.startDate]);

  const maxDate = useMemo(() => {
    if (values.endDate) return addDaysToDate(values.endDate, -1).toISOString();
    return null;
  }, [values.endDate]);

  return (
    <Content>
      <FormField label={'Event Name'}>
        <StyledTextField
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFieldValue('name', null);
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
          placeholder='Enter Event Name'
          name={'name'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.name || ''}
        />
      </FormField>

      <DateWrapper>
        <FormField label={'Event Dates'}>
          <DatePicker
            clearable
            selectedDate={values.startDate}
            setSelectedDate={(date) => {
              const dateString = date ? formatDateToString(date) : null;
              setFieldValue('startDate', dateString);
            }}
            placeholder='Start Date'
            maxDate={maxDate}
          />
        </FormField>

        <FormField label=''>
          <SwitchWrapper>
            <Switch
              checked={values.isMultiday}
              onChange={() => setFieldValue('isMultiday', !values.isMultiday)}
            />
            <Typography variant='caption' color={colors.text.main}>
              End Date
            </Typography>
          </SwitchWrapper>

          <DatePicker
            clearable
            disabled={!values.isMultiday}
            selectedDate={values.endDate}
            setSelectedDate={(date) => {
              const dateString = date ? formatDateToString(date) : null;
              setFieldValue('endDate', dateString);
            }}
            placeholder='End Date'
            maxDate={null}
            minDate={minDate}
          />
        </FormField>
      </DateWrapper>

      <FormField label={'Frequency'}>
        <SingleSelect
          listboxStyle={{ height: '195px' }}
          options={HolidayFrequencyItems}
          value={values?.frequency}
          onChange={(_, value) => {
            setFieldValue('frequency', value);
          }}
          onBlur={() => {
            setFieldTouched('frequency');
          }}
          disablePortal
          fieldPlaceholder='Select Frequency'
        />
      </FormField>

      <FormField label={'Country'}>
        <CountryWrapper>
          <Button
            variant={values?.isUSHoliday ? 'contained' : 'outlined'}
            onClick={() => {
              setFieldValue('isUSHoliday', !values.isUSHoliday);
            }}
            style={{
              backgroundColor: values?.isUSHoliday ? '' : colors.surfaceBackground.highlighted,
            }}
          >
            <Icon
              sx={{
                svg: { g: { path: { fill: colors.icon.inverse } } },
                marginRight: '4px',
              }}
            >
              <CheckIcon />
            </Icon>
            <USAFlag />
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                marginLeft: '4px',
              }}
            >
              US
            </Typography>
          </Button>
          <Button
            variant={values?.isUKHoliday ? 'contained' : 'outlined'}
            onClick={() => {
              setFieldValue('isUKHoliday', !values.isUKHoliday);
            }}
            style={{
              backgroundColor: values?.isUKHoliday ? '' : colors.surfaceBackground.highlighted,
            }}
          >
            <Icon
              sx={{
                svg: { g: { path: { fill: colors.icon.inverse } } },
                marginRight: '4px',
              }}
            >
              <CheckIcon />
            </Icon>
            <UKFlag />
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                marginLeft: '4px',
              }}
            >
              UK
            </Typography>
          </Button>
        </CountryWrapper>
      </FormField>

      <FormField label={'Event Type'}>
        <SingleSelect
          listboxStyle={{ height: '195px' }}
          options={HolidayTypeItems}
          value={values?.type}
          onChange={(_, value) => {
            setFieldValue('type', value);
          }}
          onBlur={() => {
            setFieldTouched('type');
          }}
          disablePortal
          fieldPlaceholder='Select Event Type'
        />
      </FormField>
    </Content>
  );
}

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-right: 0;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const DateWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: 12px;
  .MuiFormControl-root {
    width: 100%;
  }
`;

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: end;
`;

const CountryWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
