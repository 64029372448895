import { Tab, Tabs, useTheme } from '@mui/material';
import { TableV1 } from '../../../../components/Table/TableV1';

import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import { TableSkeletonLoader } from '../../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useOpManagerSingleTableColumns } from '../../hooks/useOpManagerSingleTableColumns';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import { useMonthlySingleTimesheet } from '../../../../queries/hooks/useMonthlySingleTimesheet';
import { useState } from 'react';
import { WeekTable } from '../../../AccountantSingleTimesheet/components/AccountantSingleTimesheetWeekTable';

export function OpManagerSingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState('month');

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const columns = useOpManagerSingleTableColumns();

  const table = useReactTable({
    data: singleTimesheet?.timesheetRows ?? [],
    columns,
    enableSorting: false,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader />
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab
          label='By Month'
          value={'month'}
          sx={{
            color: colors.text.main,
            textTransform: 'none',
          }}
        />
        <Tab
          label='By Week'
          value={'week'}
          sx={{
            color: colors.text.main,
            textTransform: 'none',
          }}
        />
      </Tabs>
      {selectedTab === 'month' && (
        <TableV1
          hasFooter
          table={table}
          height='auto'
          disableRowHover
          wrapperStyle={{
            maxHeight: 'calc(100vh - 288px)',
            overflowX: 'scroll',
            border: `1px solid ${colors.border.default}`,
          }}
        />
      )}
      {selectedTab === 'week' && (
        <>
          {singleTimesheet.weeklyTimesheets.map((timesheet, index) => (
            <WeekTable
              tableData={timesheet.timesheetRows}
              index={index}
              key={`table-${index}`}
              holidays={timesheet.holidays}
              weekDates={timesheet.weekDates}
              weekStart={timesheet.weekStart}
            />
          ))}
        </>
      )}
    </>
  );
}
