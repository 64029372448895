import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Menu,
  styled,
  useTheme,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger-menu.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-menu.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ThemeIcon } from '../../assets/icons/contrast.svg';
import { ReactComponent as DarkThemeSvg } from '../../assets/icons/dark-theme.svg';
import { ReactComponent as LightThemeSvg } from '../../assets/icons/light-theme.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon-default.svg';
import { useCallback, useMemo, useState, MouseEvent } from 'react';
import { getMenuItems } from './hooks/menuHelper';
import { useAtomValue, useAtom } from 'jotai';
import { selectedThemeState, userState } from '../../state/UIState';
import { MenuItem } from './MenuItem';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { Typography } from '../../components/Typography/Typography';
import { useIcons } from '../../hooks/useIcons';
import { THEME } from '../../types';
import { useOktaAuth } from '@okta/okta-react';
import { motion } from 'framer-motion';

const HeaderWrapper = styled('div')`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
`;

const DrawerWrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  color: ${({ theme }) => theme.colors.text.main};
`;

const ThemeOptionLabel = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MobileSidebarMenu = () => {
  const user = useAtomValue(userState);
  const { oktaAuth } = useOktaAuth();
  const { colors } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [themeMenuAnchorEl, setThemeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isThemeMenuOpen = Boolean(themeMenuAnchorEl);
  const [selectedTheme, setSelectedTheme] = useAtom(selectedThemeState);
  const { LogoutIcon } = useIcons();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleOpenUserManu = useCallback(() => {
    setIsUserMenuOpen((prev) => !prev);
  }, []);

  const handleCloseThemeMenu = () => {
    setThemeMenuAnchorEl(null);
  };

  const handleOpenThemeManu = useCallback((event: MouseEvent<HTMLElement>) => {
    setThemeMenuAnchorEl(event.currentTarget);
  }, []);

  const userMenuOptions = useMemo(() => {
    return [
      {
        id: 'PROFILE',
        value: 'Profile Information',
        icon: <UserIcon />,
      },
      {
        id: 'THEME',
        value: 'Theme',
        icon: <ThemeIcon />,
      },
      {
        id: 'LOGOUT',
        value: 'Log Out',
        icon: <LogoutIcon />,
      },
    ];
  }, [LogoutIcon]);

  const handleSelectUserMenuOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: string) => {
      e.stopPropagation();

      switch (id) {
        case 'PROFILE':
          console.log('Profile Information');
          return;
        case 'THEME':
          handleOpenThemeManu(e);
          return;
        case 'LOGOUT':
          oktaAuth.signOut();
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [handleOpenThemeManu, oktaAuth]
  );

  const themeOptions = useMemo(() => {
    return [
      {
        id: THEME.LIGHT,
        value: 'Light Theme',
        icon: <LightThemeSvg />,
      },
      {
        id: THEME.DARK,
        value: 'Dark Theme',
        icon: <DarkThemeSvg />,
      },
    ];
  }, []);

  const handleSelectThemeOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: THEME) => {
      localStorage.setItem('theme', id);
      setSelectedTheme(id);
    },
    [setSelectedTheme]
  );

  return (
    <>
      <HeaderWrapper>
        <Logo />
        <IconButton edge='end' color='inherit' aria-label='menu' onClick={toggleMenu}>
          <HamburgerIcon />
        </IconButton>
      </HeaderWrapper>
      <Drawer
        anchor='top'
        open={isMenuOpen}
        onClose={() => {
          toggleMenu();
          setIsUserMenuOpen(false);
        }}
      >
        <DrawerWrapper>
          <Box
            sx={{
              height: '48px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 16px',
            }}
          >
            <Logo />
            <IconButton edge='end' color='inherit' aria-label='menu' onClick={toggleMenu}>
              <CloseIcon />
            </IconButton>
          </Box>

          {getMenuItems(user).map((item) => (
            <MenuItem key={item.route} item={item} isExpanded={true} isSmallScreen={true} />
          ))}

          <Divider style={{ backgroundColor: colors.border.default, margin: '10px 0' }} />

          <Box
            margin='8px'
            padding='4px 8px'
            display={'flex'}
            alignItems={'center'}
            gap='12px'
            onClick={() => {
              handleOpenUserManu();
            }}
          >
            <UserAvatar name={user?.name ?? ''} style={{ minWidth: '40px' }} />
            <Typography
              variant='subtitle1'
              color={colors.text.main}
              style={{ marginBottom: '-2px' }}
            >
              Profile
            </Typography>
          </Box>
          {isUserMenuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0 }}
            >
              {userMenuOptions.map((option) => (
                <MuiMenuItem
                  onClick={(e) => handleSelectUserMenuOption(e, option.id)}
                  key={option.id}
                  style={{
                    width: '100%',
                    borderBottom:
                      option.id === 'THEME' ? `1px solid ${colors.border.default}` : 'none',
                    paddingLeft: '20px',
                  }}
                >
                  {option.icon}
                  <Typography
                    variant='body'
                    color={option.id === 'LOGOUT' ? colors.textAccent.default : colors.text.main}
                    style={{ marginLeft: '12px', marginBottom: '-2px' }}
                  >
                    {option.value}
                  </Typography>
                </MuiMenuItem>
              ))}
            </motion.div>
          )}
        </DrawerWrapper>
      </Drawer>
      <Menu
        anchorEl={themeMenuAnchorEl}
        open={isThemeMenuOpen}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseThemeMenu();
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          style: { padding: 0, border: 'none' },
          onMouseLeave: handleCloseThemeMenu,
        }}
        PaperProps={{
          style: {
            width: '240px',
            backgroundColor: colors.surfaceBackground.bg1,
          },
        }}
      >
        {themeOptions.map((option) => (
          <MuiMenuItem
            key={option.id}
            onClick={(e) => handleSelectThemeOption(e, option.id)}
            style={{ width: '100%', height: '52px', justifyContent: 'space-between' }}
          >
            <ThemeOptionLabel>
              {option.icon}
              <Typography
                variant='body'
                color={colors.text.main}
                style={{ marginLeft: '4px', marginBottom: '-2px' }}
              >
                {option.value}
              </Typography>
            </ThemeOptionLabel>
            {option.id === selectedTheme && <CheckIcon />}
          </MuiMenuItem>
        ))}
      </Menu>
    </>
  );
};
