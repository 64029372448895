import { styled, useTheme } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../constants/routes';
import { useMonthlySingleTimesheet } from '../../queries/hooks/useMonthlySingleTimesheet';
import { getMonthLabel } from '../../utils/formatters';
import { ContentWrapper } from '../layout/styled';
import { OpManagerSingleTimesheetTable } from './components/SingleTimesheetTable/OpManagerSingleTimesheetTable';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../state/UIState';

export function OpManagerSingleTimesheet() {
  const { id } = useParams();
  const location = useLocation();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const isAllTimesheets = location.pathname.includes(ROUTES.OP_MANAGER_ALL_TIMESHEETS);
  const isSubmittedTimesheets = location.pathname.includes(ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET);

  const { colors } = useTheme();
  const { data: singleTimesheet } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const breadcrumbTitle = singleTimesheet
    ? `${getMonthLabel(singleTimesheet.month)}, ${singleTimesheet.year}`
    : '';

  const breadcrumbConfig = (() => {
    if (isAllTimesheets) {
      return {
        title: 'All Timesheets',
        url: `/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
      };
    }

    if (isSubmittedTimesheets) {
      return { title: 'Submitted Timesheets', url: `/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}` };
    }

    return {
      title: '',
      url: '/',
    };
  })();

  return (
    <>
      <Wrapper>
        <Breadcrumbs
          breadcrumbs={[
            ...(!isAllTimesheets
              ? [
                  {
                    title: 'All Timesheets',
                    color: colors.textAccent.default,
                    url: `/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
                  },
                ]
              : []),
            {
              title: breadcrumbConfig?.title,
              url: breadcrumbConfig?.url,
              color: colors.textAccent.default,
            },
            { title: breadcrumbTitle || '' },
          ]}
        />
      </Wrapper>
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <OpManagerSingleTimesheetTable />
      </ContentWrapper>
    </>
  );
}

const Wrapper = styled('div')`
  padding: 28px 36px 0;
`;
