import { useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { MonthlyTimesheet, SelectItem, User, UserWithTsPeriodData } from '../../../types';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { getMonthLabelShort } from '../../../utils/formatters';
import { TSTableCheckbox } from '../components/TSTableCheckbox';
import { AccountantStatusCell } from '../components/AccountantStatusCell';
import { useAtomValue } from 'jotai';
import { isSmallScreenState } from '../../../state/UIState';

const columnHelper = createColumnHelper<UserWithTsPeriodData>();

export const useAccountantTsStatusesColumns = (timesheets: User[]) => {
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const userWithLargestTimesheet = timesheets.reduce((prevUser, currentUser) => {
    if (
      (currentUser?.monthlyTimesheets || []).length > (prevUser?.monthlyTimesheets || []).length
    ) {
      return currentUser;
    } else {
      return prevUser;
    }
  }, timesheets[0]);

  const columns = useMemo(() => {
    const fixedColumns = [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => {
          return (
            <TSTableCheckbox
              inHeader
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              <TSTableCheckbox
                row={row}
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: () => null,
                }}
              />
            </div>
          );
        },
        meta: {
          width: isSmallScreen ? '30px' : '70px',
          maxWidth: isSmallScreen ? '30px' : '70px',
          minWidth: isSmallScreen ? '30px' : '70px',
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
            avatarLetterStyle={{
              color: colors.text.main,
            }}
            name={info.getValue()}
          />
        ),
        header: () => <TableHeaderCell text='Operating Partner' />,
        sortingFn: (rowA, rowB) => {
          const valueA = rowA.original?.name || '';
          const valueB = rowB.original?.name || '';
          return valueA.localeCompare(valueB);
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.id);
        },
        meta: {
          width: isSmallScreen ? '140px' : 'auto',
          maxWidth: isSmallScreen ? '140px' : 'auto',
          minWidth: isSmallScreen ? '140px' : 'auto',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
    ];

    const periodCols = (userWithLargestTimesheet?.monthlyTimesheets || [])
      .map((ts) => `${ts.year}-${ts.month}`)
      .flatMap((period) => {
        return columnHelper.accessor(period, {
          cell: (info) => {
            return <AccountantStatusCell timesheet={info.getValue() as MonthlyTimesheet} />;
          },
          header: () => {
            const [year, month] = period.split('-');
            const monthLabel = getMonthLabelShort(Number(month));
            return <TableHeaderCell text={`${monthLabel} ${year}`} />;
          },
          meta: {
            width: isSmallScreen ? '100px' : '200px',
            maxWidth: isSmallScreen ? '100px' : '200px',
            minWidth: isSmallScreen ? '100px' : '200px',
            alignHeader: 'right',
            thStyles: { borderLeft: 'none' },
            tdStyles: { borderLeft: 'none' },
          },
          enableSorting: true,
        });
      });
    return [...fixedColumns, ...periodCols];
  }, [
    colors.surfaceIndicator.needsReview,
    colors.text.main,
    isSmallScreen,
    userWithLargestTimesheet?.monthlyTimesheets,
  ]);

  return columns;
};
