import { useAtom, useAtomValue } from 'jotai';
import { Button, useTheme } from '@mui/material';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { ReactComponent as AddIcon } from '../../assets/icons/add-white.svg';
import { Typography } from '../../components/Typography/Typography';
import { HolidayFormModal } from './components/HolidayFormModal';
import { FilterSection } from './components/FilterSection';
import {
  addHolidayModalOpen,
  holidaySelectedTab,
  removeHolidayModalOpen,
} from '../../state/UIHolidayScheduleState';
import { HolidayTab } from '../../types';
import { CalendarViewTab } from './components/CalendarViewTab';
import { ListViewTab } from './components/ListViewTab';
import { RemoveHolidaysModal } from './components/RemoveHolidaysModal';
import { isSmallScreenState } from '../../state/UIState';

export const HolidaySchedule = () => {
  const [selectedTab, setSelectedTab] = useAtom(holidaySelectedTab);
  const [holidayModalOpen, setHolidayModalOpen] = useAtom(addHolidayModalOpen);
  const removeHolidayOpen = useAtomValue(removeHolidayModalOpen);
  const { colors } = useTheme();
  const isSmallScreen = useAtomValue(isSmallScreenState);

  const handleAddHolidayModal = () => {
    setHolidayModalOpen(!holidayModalOpen);
  };

  const handleTabChange = (tab: HolidayTab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <PageHeader
        title='Event Schedule'
        breadcrumbs={[{ title: 'Administration' }, { title: 'Event Schedule' }]}
        description="Set and track your team's events"
        actionComponent={
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            style={{ height: '40px' }}
            onClick={handleAddHolidayModal}
          >
            <Typography
              variant='h4'
              color={colors.textAccent.inverse}
              style={{ marginBottom: '-1px' }}
            >
              Add Event
            </Typography>
          </Button>
        }
      />
      <ContentWrapper isSmallScreen={isSmallScreen}>
        <FilterSection selectedTab={selectedTab} handleTabChange={handleTabChange} />
        {selectedTab === HolidayTab.Calendar && <CalendarViewTab />}
        {selectedTab === HolidayTab.List && <ListViewTab />}
      </ContentWrapper>
      {holidayModalOpen && <HolidayFormModal />}
      {removeHolidayOpen && <RemoveHolidaysModal />}
    </>
  );
};
