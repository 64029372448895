import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useCallback, useMemo, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { TableV1 } from '../../../components/Table/TableV1';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { Typography } from '../../../components/Typography/Typography';
import { useDealsTableColumns } from '../hooks/useDealsTableColumns';
import { useDeals } from '../../../queries/hooks/useDeals';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { SelectItem } from '../../../types';

const Wrapper = styled('div')``;

const FiltersSection = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;

export const DealOwnerDealsTable = () => {
  const { isLoading: areDealsLoading, data: deals } = useDeals();
  const { colors } = useTheme();

  const columns = useDealsTableColumns();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedOPs, setSelectedOPs] = useState<SelectItem[]>([]);

  const table = useReactTable({
    data: deals ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (deal) => String(deal.id),
  });

  const sortedOPList = useMemo(
    () => {
      if (!deals) return [];
      const allOPs = deals
        ?.flatMap((deal) => deal.operatingPartners)
        .sort()
        .map((user) => ({ id: user?.id, value: user?.name }));

      const uniqueOPs = Array.from(new Map(allOPs.map((item) => [item.id, item])).values());
      return uniqueOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, deals]
  );

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('operatingPartners')?.setFilterValue(OPs);
      }
    },
    [table, setSelectedOPs]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    setSelectedOPs([]);
    table.getColumn('operatingPartners')?.setFilterValue([]);
    table.getColumn('name')?.setFilterValue('');
  }, [table, setSelectedOPs]);

  if (areDealsLoading || !deals) return <TableSkeletonLoader />;

  return (
    <Wrapper>
      <FiltersSection>
        <SearchInput
          placeholder='Search'
          style={{ width: '370px', height: '36px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        <Multiselect
          style={{ width: '200px', marginRight: '14px' }}
          fieldPlaceholder='Select OP'
          options={sortedOPList as SelectItem[]}
          onChange={(_, OPs) => onChangeOPFilter(OPs)}
          value={selectedOPs}
        />
        <Button
          onClick={onFiltersReset}
          variant='text'
          style={{ height: '28px', marginTop: '2px' }}
          disabled={isResetDisabled}
          sx={{
            '.MuiButton-startIcon': { marginRight: '2px' },
            '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          }}
        >
          <Typography variant='subtitle1' color={colors.textAccent.default}>
            Reset Filters
          </Typography>
        </Button>
      </FiltersSection>
      <TableV1
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          overflowX: 'scroll',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </Wrapper>
  );
};
